import React from 'react'
import LayoutConstant from '@lib/constants/layout.constant'
import GlobalLoginComplex from '@components-complex/global-login-complex/GlobalLoginComplex'
import Head from 'next/head'

function GlobalLoginPage() {
  return (
    <>
      <Head>
        <link rel="shortcut icon" href="/favicon-global.ico" />
      </Head>
      <GlobalLoginComplex />
    </>
  )
}

GlobalLoginPage.pageMeta = {
  metaTitle: 'Login',
  metaDescription: 'Global login',
  metaKeywords: '',
  path: '/global',
  layout: LayoutConstant.Empty,
}

export default GlobalLoginPage
